@import '@/styles/base/container';

.root {
  @extend .container;
  background: #282828;
  padding-top: 5rem;
  padding-bottom: 5rem;

  @screen md {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }

  .wrapper {
    display: grid;
    gap: 2rem;
    align-items: center;
    max-width: theme('maxWidth.md');

    @screen md {
      margin: auto;
      grid-template-columns: repeat(5, 1fr);
      max-width: theme('maxWidth.screen-lg');
    }

    @screen lg {
      gap: 5rem;
    }

    .image {
      display: flex;
      justify-content: flex-end;
      position: relative;

      @screen md {
        grid-column: span 3;
      }

      img {
        width: 75%;
        object-fit: cover;

        &:first-of-type {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        @screen lg {
          max-width: theme('maxWidth.xl');
        }
      }
    }

    .content {
      color: theme('colors.white');
      position: relative;

      @screen md {
        grid-column: span 2;
      }

      @screen lg {
        max-width: theme('maxWidth.xl');
      }

      > span {
        background: theme('colors.yellow.base');
        color: theme('colors.white');
        font-weight: theme('fontWeight.semibold');
      }

      h2 {
        margin-top: 1.25rem;
        font-family: theme('fontFamily.sangbleu');
        font-size: theme('fontSize.3xl');

        @screen sm {
          font-size: theme('fontSize.4xl');
        }
      }

      p {
        font-family: theme('fontFamily.grotesk');
        margin-top: 1.25rem;
      }

      > div {
        margin-top: 1.25rem;

        div {
          margin: -0.5rem;

          > * {
            margin: 0.5rem;
          }
        }
      }
    }
  }
}
